html {
  --0-013-rem: 0.013rem;
  --0-016-rem: 0.016rem;
  --0-063-rem: 0.063rem;
  --0-188-rem: 0.188rem;
  --0-125-rem: 0.125rem;
  --0-25-rem: 0.25rem;
  --0-313-rem: 0.313rem;
  --0-375-rem: 0.375rem;
  --0-5-rem: 0.5rem;
  --0-595-rem: 0.594rem;
  --0-625-rem: 0.625rem;
  --0-688-rem: 0.688rem;
  --0-75-rem: 0.75rem;
  --0-875-rem: 0.875rem;
  --0-938-rem: 0.938rem;

  --1-rem: 1rem;
  --1-125-rem: 1.125rem;
  --1-25-rem: 1.25rem;
  --1-375-rem: 1.375rem;
  --1-5-rem: 1.5rem;
  --1-563-rem: 1.563rem;
  --1-75-rem: 1.75rem;
  --1-875-rem: 1.875rem;

  --2-rem: 2rem;
  --2-188-rem: 2.188rem;
  --2-5-rem: 2.5rem;
  --2-813-rem: 2.813rem;

  --3-125-rem: 3.125rem;
  --3-438-rem: 3.438rem;
  --3-688-rem: 3.688rem;
  --3-75-rem: 3.75rem;

  --4-rem: 4rem;
  --4-125-rem: 4.125rem;
  --4-375-rem: 4.375rem;
  --4-625-rem: 4.625rem;
  --4-688-rem: 4.688rem;

  --5-rem: 5rem;
  --5-625-rem: 5.625rem;
  --5-813-rem: 5.813rem;
  --5-875-rem: 5.875rem;

  --6-25-rem: 6.25rem;
  --6-875-rem: 6.875rem;

  --7-188-rem: 7.188rem;
  --7-375-rem: 7.375rem;

  --8-125-rem: 8.125rem;

  --12-5-rem: 12.5rem;
  --18-75-rem: 18.75rem;
  --20-rem: 20rem;
  --25-rem: 25rem;
  --26-875-rem: 26.875rem;
  --31-25-rem: 31.25rem;
  --37-5-rem: 37.5rem;
}
