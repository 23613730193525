html {
  --p-white: #ffffff;
  --p-black: #000000;
  --black2: rgba(0, 0, 0, 0.2);
  --p-grey: #808080;
  --p-red: #FF0000;
  --p-mediumRed: #fa6d54;
  --p-lightRed: #f88975;

  --steel-teal: #61868f;
  --gothic: #6a8990;
  --gobelin-blue: #587d87;
  --light-blue: #87b6c3;
  --cadet-blue: #7aa7b3;
  --midnight-blue: #191970;
  --alice-blue: #e6f4fe;
  --light-grey: #d2d2d2;
  --grey2: #cccccc;
  --very-light-grey: #cfcdcd;
  --million-grey: #999999;
  --gainsboro: #d9d9d9;
  --dim-gray: #595959;
  --whitesmoke: #f0f0f0;
  --whisper: #f7f2f2;
  --silver: #c0c0c0;
}
