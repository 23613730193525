@use "./styles/global/colors";
@use "./styles/global/spacing";

.leaflet-control-container {
  position: relative;
  z-index: 999;
  font-size: var(--0-875-rem) !important;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

.leaflet-grab {
  cursor: auto !important;
}

.leaflet-layerstree-closed,
.leaflet-layerstree-opened {
  border: var(--0-063-rem) solid var(--p-white);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: var(--0-875-rem);
  width: var(--0-875-rem);
  padding: var(--0-125-rem);
}

.leaflet-top.leaflet-right .leaflet-control-layers-toggle {
  width: var(--3-438-rem);
  height: var(--3-438-rem);
}

.leaflet-top.leaflet-left .leaflet-bar {
  margin-top: var(--5-625-rem);
  border: var(--0-125-rem) solid var(--million-grey);
  margin-left: var(--0-688-rem);
}

.leaflet-top.leaflet-right .leaflet-control {
  margin-top: var(--1-125-rem);
}

.leaflet-layerstree-header input {
  margin-left: 0rem;
}

.leaflet-layerstree-header-pointer {
  cursor: pointer;
}

.leaflet-layerstree-header label {
  display: inline-block;
  cursor: pointer;
}

.leaflet-layerstree-children {
  padding-left: var(--0-625-rem);
}

.leaflet-layerstree-children-nopad {
  padding-left: 0rem;
}

.leaflet-layerstree-hide {
  display: none;
}

.leaflet-layerstree-nevershow {
  display: none;
}

.leaflet-layerstree-expand-collapse {
  cursor: pointer;
}

.leaflet-layerstree-node .leaflet-layerstree-children {
  padding-left: var(--2-5-rem);
}

.leaflet-layerstree-header-name {
  padding-left: var(--0-625-rem);
}

.leaflet-control-layers.leaflet-control a {
  color: transparent;
}

.leaflet-container {
  height: 92.3vh !important;
}

.close-icon {
  cursor: pointer;
}

.latlng {
  top: 95%;
}

.latlng-box {
  margin-left: var(--0-625-rem);
}

.clear-btn {
  color: var(--p-white);
  background: var(--steel-teal);
  margin: var(--0-625-rem);
  font-weight: 600;
  border-color: var(--steel-teal) !important;
}

.clear-btn:hover,
.clear-btn:active {
  background: var(--p-white);
  color: var(--steel-teal) !important;
}

.leaflet-control-layers label {
  font-size: var(--0-875-rem) !important;
}

.ant-select-selector:hover {
  border: var(--0-063-rem) solid var(--steel-teal) !important;
}

.leaflet-layerstree-closed,
.leaflet-layerstree-opened {
  padding: var(--0-125-rem);
  font-size: var(--1-25-rem);
}

.btn.btn-primary.disabled,
.btn.btn-primary:disabled,
fieldset:disabled .btn.btn-primary {
  background-color: var(--gothic);
  border-color: var(--gothic);
}

:where(.css-dev-only-do-not-override-11lehqq).ant-tree
  .ant-tree-node-content-wrapper.ant-tree-node-selected,
:where(.css-dev-only-do-not-override-11lehqq).ant-tree
  .ant-tree-checkbox
  + span.ant-tree-node-selected {
  background-color: var(--gothic);
}

.Toastify__toast {
  margin-top: var(--0-625-rem);
}

body,
div,
span,
button {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

:where(.css-dev-only-do-not-override-11lehqq).ant-tree-show-line
  .ant-tree-indent-unit::before {
  display: none;
}

.leaflet-control-layers-separator {
  margin: var(--0-313-rem) 0rem !important;
}

.ant-input-outlined:hover,
.ant-input-outlined:focus,
.ant-input-outlined:focus-within {
  border-color: var(--steel-teal);
}

@media (max-width: 600px) {
  .leaflet-right .leaflet-control {
    margin-left: var(--0-125-rem) !important;
  }

  .leaflet-top.leaflet-right .leaflet-control {
    margin-right: 0.52rem;
  }

  .latlng {
    z-index: 998;
    top: 90%;
  }

  .leaflet-control-layers .leaflet-control-layers-list,
  .leaflet-control-layers-expanded .leaflet-control-layers-toggle {
    width: 95vw;
  }

  .leaflet-control-layers .leaflet-control:hover {
    display: none !important;
  }

  .Toastify__toast {
    margin-top: var(--4-rem);
  }
}

@media (max-width: 768px) {
  .leaflet-container {
    height: 94.6vh !important;
  }

  .latlng-box {
    display: none;
    margin-left: var(--0-625-rem);
  }
}
